import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectProps,
  TextField,
  TextFieldProps,
  Autocomplete,
  UseAutocompleteProps,
  SxProps,
} from '@mui/material';
import {
  Controller,
  useFormContext
} from 'react-hook-form';

import { Theme } from '@mui/material/styles';

type RHFSelectProps = TextFieldProps & {
  name: string;
  native?: boolean;
  maxHeight?: boolean | number;
  children: React.ReactNode;
  handleChange?: any,
  onOpen?: any,
};

export function RHFSelect({
  name,
  native,
  maxHeight = 220,
  helperText,
  children,
  sx,
  handleChange,
  onOpen,
  ...other
}: RHFSelectProps) {

  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { error }
      }) => (



        <TextField
          value={value}
          onBlur={onBlur}
          onChange={(e) => {
            handleChange?.(e, onChange)
            onChange(e)
          }}
          select
          fullWidth
          SelectProps={{
            onOpen,
            native,
            MenuProps: {
              PaperProps: {
                sx: {
                  ...(!native && {
                    px: 1,
                    maxHeight:
                      typeof maxHeight
                        === 'number' ?
                        maxHeight
                        :
                        'unset',
                    '& .MuiMenuItem-root': {
                      px: 1,
                      borderRadius: 0.75,
                      typography: 'body2',
                      textTransform: 'capitalize',
                    },
                  }),
                },
              },
            },
            sx: {
              textTransform: 'capitalize',
              ...sx,
            },
          }}
          error={!!error}
          helperText={error ? error?.message : helperText}
          {...other}
        >
          {children}
        </TextField>
      )}
    />
  );
}

type RHFSelect2Props = Partial<UseAutocompleteProps<any, false, false, false>> & {
  name: string;
  //native?: boolean;
  //maxHeight?: boolean | number;
  //children: React.ReactNode;
  sx?: SxProps<Theme> | undefined,
  options: any,
  getOptionKey: (item: any) => string,
  getOptionLabel: (item: any) => string,
  handleChange?: any,
  label: string,
  textFieldProps?: Partial<TextFieldProps>,
  // autoComplete: string,
  //onOpen?: any,
};

export function RHFSelect2({
  name,
  label,
  textFieldProps = {},
  //native,
  //maxHeight = 220,
  //children,
  sx,
  handleChange,
  //onOpen,
  options,
  getOptionKey,
  getOptionLabel,
  autoComplete,
  ...other
}: RHFSelect2Props) {

  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { error }
      }) => (


        <Autocomplete

          {...other}

          value={options?.length ? value : ''}

          onChange={(event, newValue) => {
            // Assuming newValue is the selected option object
            const newValueId = newValue ? getOptionKey(newValue) : value;
            handleChange?.(event, newValueId, onChange);
            onChange(newValueId); // Set the _id as the new value
          }}


          options={options}
          // getOptionKey={getOptionKey}
          getOptionLabel={getOptionLabel}


          sx={sx}

          autoHighlight

          // renderOption={(props, option) => (
          //   <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
          //     <img
          //       loading="lazy"
          //       width="20"
          //       srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
          //       src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
          //       alt=""
          //     />
          //     {option.label} ({option.code}) +{option.phone}
          //   </Box>
          // )}

          renderInput={(params: any) => (
            <TextField
              {...params}
              onBlur={onBlur}
              label={label}
              error={!!error}
              helperText={error ? error?.message : textFieldProps.helperText}
              {...textFieldProps}
              inputProps={{
                ...params.inputProps,
                // autoComplete: autoComplete, //'new-password', // disable autocomplete and autofill
              }}
            />
          )}



        />

      )}
    />
  );
}

type RHFMultiSelectProps = SelectProps & {
  name: string;
  label?: string;
  chip?: boolean;
  checkbox?: boolean;
  placeholder?: string;
  helperText?: React.ReactNode;
  options: {
    label: string;
    value: string;
  }[];
};

export function RHFMultiSelect({
  name,
  chip,
  label,
  options,
  checkbox,
  placeholder,
  helperText,
  sx,
  ...other
}: RHFMultiSelectProps) {
  const { control } = useFormContext();

  const renderValues = (selectedIds: string[]) => {
    const selectedItems
      = options.filter((item) =>
        selectedIds.includes(item.value)
      )

    if (!selectedItems.length && placeholder) {
      return (
        <Box component="em" sx={{ color: 'text.disabled' }}>
          {placeholder}
        </Box>
      );
    }

    if (chip) {
      return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
          {selectedItems.map((item) => (
            <Chip key={item.value} size="small" label={item.label} />
          ))}
        </Box>
      );
    }

    return selectedItems.map((item) => item.label).join(', ');
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl sx={sx}>
          {label && <InputLabel id={name}> {label} </InputLabel>}

          <Select
            {...field}
            multiple
            displayEmpty={!!placeholder}
            labelId={name}
            input={<OutlinedInput fullWidth label={label} error={!!error} />}
            renderValue={renderValues}
            MenuProps={{
              PaperProps: {
                sx: { px: 1, maxHeight: 280 },
              },
            }}
            {...other}
          >
            {placeholder && (
              <MenuItem
                disabled
                value=""
                sx={{
                  py: 1,
                  px: 2,
                  borderRadius: 0.75,
                  typography: 'body2',
                }}
              >
                <em> {placeholder} </em>
              </MenuItem>
            )}

            {options.map((option) => {
              const selected = field.value.includes(option.value);

              return (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  sx={{
                    py: 1,
                    px: 2,
                    borderRadius: 0.75,
                    typography: 'body2',
                    ...(selected && {
                      fontWeight: 'fontWeightMedium',
                    }),
                    ...(checkbox && {
                      p: 0.25,
                    }),
                  }}
                >
                  {checkbox &&
                    <Checkbox
                      disableRipple
                      size="small"
                      checked={selected}
                    />
                  }

                  {option.label}
                </MenuItem>
              );
            })}
          </Select>

          {(!!error || helperText) && (
            <FormHelperText
              error={!!error}
            >
              {error ?
                error?.message
                :
                helperText
              }
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}
