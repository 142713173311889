import {
  FormProvider as Form,
  UseFormReturn
} from 'react-hook-form';

type Props = {
  children: React.ReactNode;
  methods: UseFormReturn<any>;
  onSubmit?: VoidFunction;
};

function FormProvider({
  children,
  onSubmit,
  methods
}: Props) {


  return (
    <Form {...{ ...methods, onSubmit }}  >
      {children}
    </Form >
  );
}

export default FormProvider