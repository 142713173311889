import {
  IconButton,
  InputAdornment,
  styled,
  TextField as MuiTextField,
  TextFieldProps
} from '@mui/material';
import moment from 'moment-timezone';
import { MuiTelInput } from 'mui-tel-input'
import React, {
  ChangeEvent,
  useState,
  useMemo,
  Ref,
} from 'react';
import {
  Controller,
  useFormContext
} from 'react-hook-form';
import Iconify from '~components/iconify/Iconify';

const TextField
  = styled(MuiTextField)(() => ({
    '&': {
      background: 'transparent',
    }
  }))

type Props = TextFieldProps & {
  name: string;
  hideError?: boolean
  startAdornment?: any,
  mask?: any,
};

function RHFPhoneField({
  name,
  helperText,
  hideError,
  onChange,
  startAdornment,
  mask, // Add mask prop for IMaskInput
  ...other
}: Props) {

  const {
    control
  } = useFormContext()

  const [showPassword, setShowPassword]
    = useState(false)

  // const handleChange
  //   = ({
  //     e,
  //     field
  //   }: any) => {

  //     let value = e.value

  //     field.onChange(value)
  //     if (onChange)
  //       onChange(value)
  //   }
  // const PhoneFieldComponent = useMemo(() => {
  //   return React.forwardRef((props, ref) => {
  //     return <MuiTelInput {...props} inputRef={ref as Ref<HTMLInputElement> | undefined} />;
  //   });
  // }, []);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <MuiTelInput
          defaultCountry='US'
          {...field}
          // onChange={(e: any) =>
          //   handleChange({
          //     e,
          //     field
          //   })}
          fullWidth
          value={
            field.value || ''
          }
          error={!!error}
          helperText={(error && !hideError) ?
            error?.message
            :
            helperText
          }


          {...(startAdornment && {
            InputProps: {
              sx: {
                background: 'white',
              },
              startAdornment: startAdornment
            }
          })
          }

          {...other}
        />
      )}
    />
  );
}

export default RHFPhoneField